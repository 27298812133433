<template>
  <div class="">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <h2 class="mb-4">Minhas Lojas</h2>
    <v-card v-if="!stores.length && !loading" outlined>
      <div
        class="d-flex flex-column align-center justify-center"
        style="height: 400px"
      >
        <v-icon size="100"> mdi-store </v-icon>
        <h1 class="text-center">Você não tem acesso a nenhuma loja</h1>
      </div>
    </v-card>
    <div v-else class="d-flex flex-column gap-1">
      <v-card
        v-for="store in stores"
        :key="store.id"
        class="pa-4"
        @click="goTo(store)"
      >
        <div v-if="!store.Condominium" class="d-flex align-center gap-2">
          <div>
            <v-img
              v-img
              :src="store.Brand?.logo"
              height="100"
              width="100"
              contain
            />
          </div>
          <div>
            <h3 class="text-center">Loja {{ store.Brand.name }}</h3>
            <p class="mb-0">Ainda não configurada</p>
          </div>
        </div>
        <div v-else class="d-flex align-center gap-2">
          <div>
            <v-img
              v-img
              :src="store.Brand?.logo"
              height="100"
              width="100"
              contain
            />
          </div>
          <div>
            <p
              class="mb-0 text-overline font-weight-medium"
              style="line-height: 1rem"
            >
              Loja {{ store.Brand.name }}
            </p>
            <h3 class="text-center">{{ store.Condominium.name }}</h3>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("store", ["stores"]),
  },
  mounted() {
    this.getStoresData();
  },
  methods: {
    ...mapActions("store", ["getStores"]),
    goTo(store) {
      if (store.Condominium) {
        this.$router.push({
          name: "store.index",
          params: { id: store.id },
        });
      } else {
        this.$router.push({
          name: "init-store",
          params: { id: store.id },
        });
      }
    },
    getStoresData() {
      try {
        this.loading = true;
        this.getStores();
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
